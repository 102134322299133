import styled from 'styled-components'

import { IconClose } from 'shared/ui/Icon/General/IconClose'

export const StyledBroadcastWrapper = styled.div`
  max-width: 280px;
  position: relative;
`

export const StyledBroadcastHeader = styled.div`
  align-items: center;
  background-color: ${({ theme }) => theme.colors.fixed.primary[90]};
  display: flex;
  height: 20px;
  justify-content: space-between;
  padding: 2px 4px;
`

export const StyledBroadcastTitle = styled.div`
  align-items: center;
  display: flex;
  gap: 8px;
`

export const StyledCompetitors = styled.span`
  color: ${(props) => props.theme.colors.fixed.primary[0]};
  font-size: ${(props) => props.theme.fonts.size.xs};
  font-weight: ${(props) => props.theme.fonts.weight.bold};
  max-width: 230px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
`

export const StyledNotAuthorized = styled.div`
  align-items: center;
  background-color: ${(props) => props.theme.colors.custom.primary[4]};
  display: flex;
  flex-direction: column;
  gap: 8px;
  height: 175px;
  justify-content: center;
`

export const StyledNotAuthorizedText = styled.span`
  color: ${(props) => props.theme.colors.custom.primary[16]};
  font-size: ${(props) => props.theme.fonts.size.s};
  text-align: center;
`

export const StyledCloseIcon = styled(IconClose)`
  cursor: pointer;
  svg {
    color: ${({ theme }) => theme.colors.fixed.primary[60]};
  }
`
